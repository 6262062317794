import React, { useState, useEffect } from "react"
import Title from "./Title"

import enterprises from "../constants/enterprises"

import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, A11y]);

const CollaborativeWork = () => {
  const windowGlobal = typeof window !== 'undefined' && window;
  const [swiperSlidesPerView, setSwiperSlidePerView] = useState(windowGlobal.innerWidth >= 992 ? 1 : 1);
  const [swiperCenteredSlides, setSwiperCenteredSlides] = useState(windowGlobal.innerWidth >= 992 ? false : true);

  useEffect(() => {
    windowGlobal.addEventListener('resize', (e) => {
      if (windowGlobal.innerWidth >= 992) {
        setSwiperSlidePerView(1)
        setSwiperCenteredSlides(false)
      } else {
        setSwiperSlidePerView(1)
        setSwiperCenteredSlides(true)
      }
    });
  }, [ windowGlobal ])

	return (
    <section className="section collaborative-work bg-grey">
			<div className="section-inner">
				<Title title="Alianzas y trabajo colaborativo" />
				<div className="collaborative-work-center">
					<Swiper
						id="collaborativeWorkSwiper"
						direction="horizontal"
						autoplay={false}
						loop={true}
						spaceBetween={15}
						centeredSlides={swiperCenteredSlides}
						slidesPerView={swiperSlidesPerView}
						grabCursor={true}
						pagination={{
							pagination: {
								el: '.swiper-pagination',
								type: 'bullets',
								clickeable: true
						}}}
						onDoubleClick={(swiper) => {console.log(swiper.clickedSlide)}}
					>
						{
							enterprises.map(enterprise => {
								const { id, name, img, url } = enterprise;

								return (
									<SwiperSlide
										key={id}
										id={id}
									>
										<a href={url} target="_blank" rel="noreferrer">
											<img
												src={img}
												alt={name}
												width="auto"
												height="auto"
												// style={{filter: 'grayscale(100%)'}}
											/>
										</a>
									</SwiperSlide>
								)
							})
						}
					</Swiper>
        </div>
      </div>
    </section>
  )
}

export default CollaborativeWork
