import React from "react"
import { FaCode, FaDesktop, FaMobileAlt } from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image";

const services = [
  {
    id: 1,
    icon: <FaCode className="service-icon" size={42} />,
    img: <StaticImage src="../assets/images/web-apps.svg" height={300} placeholder="blurred" alt="web apps" key="web apps img" />,
    title: "Desarrollo web",
    text: `Apps web PMA o PSA según sea los requerimientos demandados`,
  },
  {
    id: 2,
    icon: <FaDesktop className="service-icon" size={42} />,
    img: <StaticImage src='../assets/images/desktop-apps.svg' height={300} placeholder="blurred" alt="desktop apps" key="desktop apps img" />,
    title: "Desarrollo de escritorio",
    text: `Apps de escritorio desarrolladas con ElectronJS`,
  },
  {
    id: 3,
    icon: <FaMobileAlt className="service-icon" size={42} />,
    img: <StaticImage src='../assets/images/mobile-apps.svg' height={300} placeholder="blurred" alt="mobile apps" key="mobile apps img" />,
    title: "Desarrollo móvil",
    text: `Apps móviles desarrolladas con React Native`,
  },
]

export default services
