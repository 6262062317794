import React from "react"
import { FaEnvelope, FaWpforms, FaGithub, FaRegIdCard } from 'react-icons/fa'

// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next'

import { motion } from "framer-motion"
import Typist from 'react-typist'

import 'animate.css'

import socialLinks from "../constants/social_links"

const Hero = () => {
	const {t} = useI18next();
	const variants = {
		visible: { opacity: 1 },
		hidden: { opacity: 0 },
	}
	return (
		<header className="hero bg-grey">
			<section className="section-center hero-center">
				<article className="hero-info">
					<div className="underline"></div>
					<div style={{marginTop: "1rem", marginBottom: "1rem"}}>
						<h3 style={{wordBreak: "break-word", overfloWrap:"break-word"}}>
							<Typist
								avgTypingDelay={35}
								cursor={{
									show: false,
									blink: true,
									element: '✍️',
									hideWhenDone: true,
									hideWhenDoneDelay: 1000,
								}}
							>
								{t("¡Hola! bienvenid@")}
								<span role="img" aria-label="greetings" aria-labelledby="greetings" className="waving-hand" style={{paddingLeft: "1rem"}}>👋</span>
							</Typist>
						</h3>
						<h1 style={{textTransform:"unset"}}>
							<Typist
								avgTypingDelay={35}
								cursor={{
									show: false,
									blink: true,
									element: '✍️',
									hideWhenDone: true,
									hideWhenDoneDelay: 1000,
								}}
							>
								<Typist.Delay ms={2000} />
								{t("Soy")} Kristián Roa C.
							</Typist>
						</h1>
						<h2>
							<Typist
								avgTypingDelay={35}
								cursor={{
									show: false,
									blink: true,
									element: '✍️',
									hideWhenDone: true,
									hideWhenDoneDelay: 1000,
								}}
							>
								<Typist.Delay ms={3500} />
								{t("Ingeniero de software freelancer")}
							</Typist>
						</h2>
						<h3>
							<Typist
								avgTypingDelay={35}
								cursor={{
									show: false,
									blink: true,
									element: '✍️',
									hideWhenDone: true,
									hideWhenDoneDelay: 1000,
								}}
							>
								<Typist.Delay ms={5500} />
								{t("Un placer conocerte")}
								<span role="img" aria-labelledby="smile face">&nbsp;😃</span>
							</Typist>
						</h3>
					</div>
					<div className="main-info">
						{/* <motion.div
							initial="hidden"
							animate="visible"
							transition={{ delay: 7.5 }}
							variants={ variants }
						>
							<h4 style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: '1rem', textTransform:"unset"}}>
								<Trans>Para conocer mi forma de trabajo haz click en</Trans>&nbsp;
								<Link to="/about" className="btn" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem'}}>
									<FaRegIdCard size={22} />&nbsp;
									<Trans>Sobre mi</Trans>
								</Link>
							</h4>
						</motion.div> */}
						{/* <motion.div
							initial="hidden"
							animate="visible"
							transition={{ delay: 7.5 }}
							variants={ variants }
						>
							<h4 style={{textTransform:"unset"}}>
								<Trans>Si necesitas de mis servicios puedes hacer click en</Trans>
							</h4>
							<p style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>
								<Link to="/contact" className="btn" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem'}}>
									<FaWpforms size={22} />&nbsp;
									<Trans>Ponerse en contacto</Trans>
								</Link>
								<Trans>o</Trans>
								<a href="mailto:kon.991@gmail.com" className="btn" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem'}}>
									<FaEnvelope size={22} />&nbsp;
									<Trans>Enviar un correo rápido</Trans>
								</a>
							</p>
							<h4 style={{textTransform: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.175rem'}}>
								<FaAngleDoubleRight size={22} />
								<Trans>Tardo 48 hrs en responder a más tardar</Trans>&nbsp;
								<span role="img" aria-label="ok" aria-labelledby="ok" style={{fontSize: '24px'}}>😉</span>
							</h4>
						</motion.div> */}
					</div>
					<div className="social-links">
						<motion.a
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							transition={{ duration: 0.4, delay: 7}}
							href="https://github.com/conka991"
							rel="nofollow"
							key="githubProfile"
							className="social-link"
						>
							<FaGithub />
						</motion.a>
						{socialLinks.map((link, index) => {
							return (
								<motion.a
									key={link.id}
									initial={{ scale: 0 }}
									animate={{ scale: 1 }}
									transition={{ duration: 0.5 + (index * 0.2), delay: 7}}
									href={link.url}
									target="_blank"
									rel="nofollow"
									className="social-link"
								>
									{link.icon}
								</motion.a>
							)
						})}
					</div>
					<div className="underline"></div>
				</article>
				<div className="hero-img animate__animated animate__zoomIn">
					<StaticImage
						src="../assets/images/me.png"
						alt="portfolio"
						key="portfolio img"
						layout="fixed"
						width={350}
						placeholder="blurred"
					/>
				</div>
			</section>
			<div className="custom-shape-divider-bottom-1624477528">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
					<path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
					<path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
					<path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
				</svg>
			</div>
		</header>
	)
}

export default Hero
