import digitalstudioLogo from '../assets/images/digitalstudio-spa.png';

const enterprises = [
	{
		id: "digitalstudio-spa",
		name: "DigitalStudio SpA",
		url: "https://www.digitalstudio.cl/",
		img: digitalstudioLogo
	}
];

export default enterprises;