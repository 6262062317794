import React, { useState, useEffect, useRef } from "react"

import useIntersection from "../hooks/useIntersection";

import { FaAngleDoubleRight, FaCalendarAlt, FaUserTie, FaFingerprint } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"

import SimpleBar from 'simplebar-react';
import "simplebar/dist/simplebar.min.css";

import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import Title from "./Title"

SwiperCore.use([Navigation, Pagination, A11y]);


const query = graphql`
  {
    allStrapiJob(sort: {order: DESC, fields: created_at}) {
      nodes {
        locale
        position
        company
        date
        desc {
          name
          id
        }
      }
    }
  }
`

const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const Jobs = ({ locale }) => {
  const data = useStaticQuery(query);
  const { allStrapiJob: { nodes: jobs } } = data;

	const jobsGroupedByCompany = groupBy(jobs, 'company');
  const [ companyAsArray, setCompany ] = useState(Object.entries(jobsGroupedByCompany)[0]);
	const [ companySelected, jobsOnThatCompany ] = companyAsArray;

  const windowGlobal = typeof window !== 'undefined' && window;
  const [swiperOrientation, setSwiperOrientation] = useState(windowGlobal.innerWidth >= 992 ? "vertical" : "horizontal");
  const [swiperSlidesPerView, setSwiperSlidePerView] = useState(windowGlobal.innerWidth >= 992 ? 5 : 1);
  const [swiperCenteredSlides, setSwiperCenteredSlides] = useState(windowGlobal.innerWidth >= 992 ? false : true);

  const jobsRef = useRef();
  const inViewportServices = useIntersection(jobsRef, '-200px'); // Trigger if 200px is visible from the element
  const swiperClasses = inViewportServices ? 'btn-container animate__animated animate__fadeInLeft' : 'btn-container';
  const swiperDescriptionClasses = inViewportServices ? 'jobs-selected animate__animated animate__fadeInRight' : 'jobs-selected';

  useEffect(() => {
    const onResize = e => {
      if (windowGlobal.innerWidth >= 992) {
        setSwiperOrientation('vertical')
        setSwiperSlidePerView(5)
        setSwiperCenteredSlides(false)
      } else {
        setSwiperOrientation('horizontal')
        setSwiperSlidePerView(1)
        setSwiperCenteredSlides(true)
      }
    };
    windowGlobal.addEventListener('resize', onResize);

    return () => windowGlobal.removeEventListener("resize", onResize);
  }, [windowGlobal]);

	return (
    <section className="section jobs bg-grey" ref={jobsRef}>
      <div className="section-inner">
        <Title title="Experiencia de trabajo" />
        <div className="jobs-center">
          <div className={swiperClasses}>
            <Swiper
              id="companiesSwiper"
              direction={swiperOrientation}
              autoplay={false}
              loop={false}
              spaceBetween={15}
              centeredSlides={swiperCenteredSlides}
              slidesPerView={swiperSlidesPerView}
              grabCursor={true}
              pagination={{
                pagination: {
                  el: '.swiper-pagination',
                  type: 'bullets',
                  clickeable: true
              }}}
            >
              {
                Object.entries(jobsGroupedByCompany).map((jGBC, index) => {
                  const [ company ] = jGBC;
                  const classesToAppend = company === companySelected ? 'job-btn active-btn' : 'job-btn';

                  return (
                    <SwiperSlide key={index} id={company.replace(/ /g, "_")} className="swiper-slide-clickeable" onClick={() => setCompany(Object.entries(jobsGroupedByCompany)[index])}>
                      <div className={classesToAppend}>
                        <FaFingerprint size={26} />
                        {company}
                      </div>
                    </SwiperSlide>
                    // <button
                    //   key={index}
                    //   className={company === companySelected ? 'job-btn active-btn' : 'job-btn'}
                    //   onClick={() => setCompany(Object.entries(jobsGroupedByCompany)[index])}
                    //   style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}
                    // >
                    //   <FaFingerprint size={26} />
                    //   {company}
                    // </button>
                  )
                })
              }
            </Swiper>
          </div>
          <div className={swiperDescriptionClasses}>
            {
              jobsOnThatCompany.map((job, index) => {
                if (job.locale.indexOf(locale) === -1) return '';
                const { position, date, desc } = job;

                return (
                  <article key={"job_" + index} className="job-info">
                    <h3><FaUserTie size={32} />&nbsp;{position}</h3>
                    <p className="job-date"><FaCalendarAlt size={20} />&nbsp;{date}</p>
                    <SimpleBar style={{ maxHeight: 400}}>
                      {
                        desc.map((item) => {
                          return (
                            <div key={item.id} className="job-desc">
                              <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                              <p>{item.name}</p>
                            </div>
                          )
                        })
                      }
                    </SimpleBar>
                  </article>
                );
              })
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Jobs
