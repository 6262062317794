import React from "react"

import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"

import Layout from "../components/Layout";
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import Services from "../components/Services"
import TechStackUsed from "../components/TechStackUsed"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import CollaborativeWork from "../components/CollaborativeWork"

const IndexPage = ({ data }) => {
  const { allStrapiProject: { nodes: projects } } = data;
  const { language } = useI18next();

  return (
    <Layout>
      <Seo title="Inicio" />
      <main className="index-page">
        <Hero />
        <Services />
        <section className="semicircle"></section>
        <TechStackUsed />
        <section className="semicircle"></section>
        <Jobs locale={language} />
        <section className="semicircle"></section>
        <Projects
          title="Proyectos destacados"
          projects={projects}
          showLink={true}
          locale={language}
        />
        <section className="semicircle"></section>
        <CollaborativeWork />
      </main>
    </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiProject(filter: { featured: { eq: true } }) {
      nodes {
        image {
          created_at
          url
          localFile {
            url
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FIXED,
                blurredOptions: {},
                placeholder: BLURRED,
                height: 260,
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        locale
        github
        featured
        description
        slug
        title
        url
        stack {
          id
          title
        }
        id
        locale
      }
    }
  }
`;