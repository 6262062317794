import React, { useState, useEffect } from "react"

import Title from "./Title"

import gatsbyLogo from '../assets/images/gatsby-logo.svg';
import reactLogo from '../assets/images/react-logo.svg';
import reactNativeLogo from '../assets/images/react-native-logo.svg';
import electronjsLogo from '../assets/images/electronjs-logo.svg';
import expressLogo from '../assets/images/expressjs-logo.svg';
import nextjsLogo from '../assets/images/nextjs-logo.svg';
import bootstrapLogo from '../assets/images/bootstrap-logo.svg';
import materializeLogo from '../assets/images/materialize-logo.svg';
import materialuiLogo from '../assets/images/material-ui-logo.svg';
import cakephpLogo from '../assets/images/cakephp-logo.svg';
import graphqlLogo from '../assets/images/graphql-logo.svg';
import mariadbLogo from '../assets/images/mariadb-logo.svg';
import mongodbLogo from '../assets/images/mongodb-logo.svg';

import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Autoplay]);

const TechStackUsed = () => {
	const windowGlobal = typeof window !== 'undefined' && window;
  const [slidesPerViewSwiper, setSlidesPerView] = useState(windowGlobal.innerWidth >= 992 ? 5 : 2);

  useEffect(() => {
    windowGlobal.addEventListener('resize', (e) => {
      if (windowGlobal.innerWidth >= 992) {
        setSlidesPerView(5)
      } else {
        setSlidesPerView(2)
      }
    });
  }, [windowGlobal])

	return (
		<section className="section tech-stack">
			<div className="section-inner">
				<Title title="Stack de tecnologías utilizadas" />
				<Swiper
					id="techStackSwiper"
					autoplay={true}
					speed={3000}
					loop={true}
					spaceBetween={35}
					centeredSlides={true}
					slidesPerView={slidesPerViewSwiper}
					loopFillGroupWithBlank={true}
					preventClicks={true}
					preventClicksPropagation={true}
					preventInteractionOnTransition={true}
					allowTouchMove= {false}
					grabCursor={false}
				>
					<SwiperSlide>
						<img
							src={cakephpLogo}
							alt="cakephp logo"
							key="cakephp logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={gatsbyLogo}
							alt="gatsby logo"
							key="gatsby logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={reactLogo}
							alt="react logo"
							key="react logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={reactNativeLogo}
							alt="reactNative logo"
							key="reactNative logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={electronjsLogo}
							alt="electronjs logo"
							key="electronjs logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={graphqlLogo}
							alt="graphql logo"
							key="graphql logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={expressLogo}
							alt="expressjs logo"
							key="expressjs logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={nextjsLogo}
							alt="nextjs logo"
							key="nextjs logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={mariadbLogo}
							alt="mariadb logo"
							key="mariadb logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={mongodbLogo}
							alt="mongodb logo"
							key="mongodb logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={bootstrapLogo}
							alt="bootstrap logo"
							key="bootstrap logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={materializeLogo}
							alt="materialize logo"
							key="materialize logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={materialuiLogo}
							alt="materialui logo"
							key="materialui logo img"
							width="auto"
							height="auto"
						/>
					</SwiperSlide>
				</Swiper>
			</div>
		</section>
	)
}

export default TechStackUsed