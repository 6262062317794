import React, { useRef } from "react"

import { Trans } from "gatsby-plugin-react-i18next"
// import { StaticImage } from "gatsby-plugin-image"

import { motion } from "framer-motion"

import Title from "./Title"
import services from "../constants/services"

import useIntersection from "../hooks/useIntersection"

const Services = () => {
  const servicesRef = useRef();
  const inViewportServices = useIntersection(servicesRef, '-20px'); // Trigger if 200px is visible from the element
  let toRender = null;

  if (inViewportServices) {
    toRender = services.map((service, index) => {
      const {id, icon, img, title, text} = service;
      const animatedClasses = `service animate__animated animate__bounceInDown animate__delay-${index}s`

      return (
        <div key={id} className={animatedClasses}>
          <motion.article
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.03 },
            }}
          >
            <div className="service-img">{img}</div>
            <div className="service-icon">{icon}</div>
            <h4 style={{margin: '1rem'}}>
              <Trans>{title}</Trans>
            </h4>
            <div className="underline"></div>
            <p>
              <Trans>{text}</Trans>
            </p>
          </motion.article>
        </div>
      )
    })
  }

  return (
    <section className="section section-services bg-grey">
      <div className="section-inner">
        <Title title="Servicios que ofrezco"  />
        <div className="section-center services-center" ref={servicesRef}>
          {toRender}
        </div>
      </div>
    </section>
  )
}

export default Services
